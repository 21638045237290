import React, { Component } from 'react';

import colors from 'helpers/colors';

import PalettePicker from 'components/common/PalettePicker';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
    flex: {
        flex: 1,
    },
    dialogContentTop: {
        padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px`,
    },
    dialogPreview: {
        display: 'block',
        width: '100%',
        marginTop: theme.spacing.unit,
        padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 2}px`,
        height: theme.spacing.unit * 4,
        // border: '1px solid black',
        borderRadius: theme.spacing.unit / 2,
    },
});

class ColorPickerDialog extends Component {
    state = {
        currentColor: colors.defaultColor,
    };

    handleColorChange = (currentColor) => {
        this.setState({ currentColor });
    };

    handleOnCancel = () => {
        this.props.onCancel();
    };

    handleOnClose = () => {
        this.props.onClose({ color: this.state.currentColor });
    };

    componentDidMount = () => {
        this.setState({ currentColor: colors.normalize(this.props.color) });
    };

    render() {
        const {
            color,
            onCancel,
            onClose,
            classes,
            onChange,
            ...DialogProps
        } = this.props;

        const {
            currentColor,
        } = this.state;

        const previewStyle = {
            color: currentColor,
            backgroundColor: colors.getContrastColor(currentColor),
        };

        const colorDialog = (
            <Dialog
                {...DialogProps}>
                <AppBar
                    position="static">
                    <Toolbar>
                        <Typography variant="h6" color="inherit" className={classes.flex}>Select color</Typography>
                        <IconButton color="inherit" onClick={this.handleOnCancel} className="toolbar-icon-right" aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent
                    className={ classes.dialogContentTop }>
                    <Typography variant="subtitle1" color="inherit" className={classes.flex}>Text color:</Typography>
                    <div
                        className={ classes.dialogPreview }
                        style={previewStyle}>
                        <Typography variant="subtitle1" color="inherit" className={classes.flex}>Sample text</Typography>
                    </div>
                </DialogContent>
                <Divider />
                <DialogContent>
                    <PalettePicker
                        label={`Main color`}
                        color={ currentColor }
                        onChange={ this.handleColorChange } />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleOnCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleOnClose} color="primary">
                        Select
                    </Button>
                </DialogActions>
            </Dialog>
        );

        return colorDialog;
    }
}

export default withMobileDialog()(withStyles(styles)(ColorPickerDialog));
