import React, { Component } from 'react';
import ReactFileReader from 'react-file-reader';

import FileUploadErrorDialog from 'components/common/dialogs/FileUploadErrorDialog';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    container: {
        flex: 1,
        display: 'flex',
    },
});

class ImageUploader extends Component {

    state = {
        invalidFileType: false,
    };

    onUploadFiles = (files) => {
        this.setState({ invalidFileType: false });
        console.log(files.fileList[0].type);
        if (!files.fileList[0].type.match(/^image\//i)) {
            this.setState({ invalidFileType: true });
            return;
        }
        this.props.handleFiles(files);
    };

    render() {
        const { className, children, classes } = this.props;

        return <React.Fragment>
            <ReactFileReader
                className={`${className} ${classes.container}`}
                base64={true}
                handleFiles={this.onUploadFiles}>
                { children }
            </ReactFileReader>
            <FileUploadErrorDialog
                open={this.state.invalidFileType}
                title="Invalid file type"
                content="Only images are allowed."
                handleCancel={() => this.setState({ invalidFileType: false })}
                handleClose={this.onUploadFiles} />
        </React.Fragment>
    };
}

export default withStyles(styles)(ImageUploader);
