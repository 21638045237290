import React, { Component } from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Dialog from "@material-ui/core/Dialog/Dialog";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Home from '@material-ui/icons/Home';
import Settings from '@material-ui/icons/Settings';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import ContactMail from '@material-ui/icons/ContactMail';
import Poll from '@material-ui/icons/Poll';
import ListIcon from '@material-ui/icons/List';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
    drawerContainer: {
        display: 'flex',
    },
    drawerPaper: {
        width: 240,
        borderRight: 0,
        backgroundColor: theme.palette.primary.main,
        '& p, & h6, & $listItem, & $listItem div, & $listItem div span': {
            color: '#e7f0f5',
        },
        '& $listItem.active, & $expandableList, & $nested': {
            backgroundColor: '#161d25',
            '& div span': {
                color: '#fff',
            },
        },
        [theme.breakpoints.up('md')]: {
            width: 320,
            position: 'relative',
        },
    },
    instanceInfo: {
        padding: '24px 0px 16px 0',
    },
    switchInstance: {
        padding: '8px 0',
        backgroundColor: '#161d25',
        textAlign: 'center',
        '& button span span': {
            color: '#30b6e1',
        },
    },
    listContainer: {
        overflow: 'auto',
    },
    expandableList: {},
    nested: {},
    listItem: {},
    separator: {
        backgroundColor: '#fff',
    },
    footerText: {
        color: '#fff',
        padding: '8px 0 0px 12px',
    },
    footerPaper: {
        margin: '8px 12px',
        padding: '8px',
    },
    logo: {
        verticalAlign: 'middle',
        margin: '0 4px',
    },
    dialogContainer: {
        padding: '60px 0 0 0',
    },
    dialog: {
        '& > div > div': {
            backgroundColor: '#e7f0f5',
        },
    },
    dialogHeader: {
        fontWeight: 'normal',
        [theme.breakpoints.down('md')]: {
            fontSize: 24,
            margin: 8,
            textAlign: 'center'
        },
    },
    dialogPaper: {
        padding: '24px',
        margin: '8px',
        textAlign: 'left',
    },
    closeDialogContainer: {
        position: 'absolute',
        right: 0,
        top: 0,
    },
    closeDialogIcon: {
        fontSize: '36px',
        [theme.breakpoints.down('md')]: {
            fontSize: 24,
        },
    },
    linkButton: {
        textTransform: 'unset',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        textDecoration: 'none',
        display: 'inline',
        margin: 0,
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    instanceContainer: {
        width: '100%',
        maxWidth: 440,
    },
    instanceButton: {
        width: '100%',
    },
});

class SideDrawer extends Component {
    state = {
        mobileOpen: false,
        openedMenu: null,
        dialogOpen: false,
    };
    static getDerivedStateFromProps = (props) => {
        return { mobileOpen: props.isOpen };
    };

    expandMenu(key) {
        this.setState({
            openedMenu: this.state.openedMenu === key ? null : key,
        });
    }

    isMenuOpened(key) {
        return this.state.openedMenu === key;
    }

    handleClickOpen = () => {
        this.setState({
            dialogOpen: true,
        });
    };

    handleClose = () => {
        this.setState({
            dialogOpen: false,
        });
    };

    changeInstance = (id) => {
        const { onInstanceChange } = this.props;

        onInstanceChange(id);
        this.handleClose();
    };

    render() {
        const { mobileOpen, dialogOpen } = this.state;
        const { classes, appName, theme, handleDrawerToggle, instances, activeInstance } = this.props;
        const instance = instances.find(instance => instance.id === activeInstance);

        const drawer = (
            <Grid item xs container spacing={0} direction="column">
                <Grid item container direction="column" alignItems="center" className={classes.instanceInfo}>
                    <Typography
                        variant="body2"
                        color="default">{appName}</Typography>
                    <Typography
                        variant="h6"
                        color="default">{instance.name}</Typography>
                </Grid>
                <Grid item>
                    <div className={classes.switchInstance}>
                        <Button className={classes.linkButton} onClick={this.handleClickOpen}>
                            <Typography
                                variant="caption"
                                color="default">Switch Customer Portal ({instances.length}) </Typography>
                        </Button>
                    </div>
                </Grid>
                <Grid item xs className={classes.listContainer}>
                    <List>
                        <ListItem
                            className={classes.listItem}
                            button>
                            <ListItemIcon>
                                <Home />
                            </ListItemIcon>
                            <ListItemText>DASHBOARD</ListItemText>
                        </ListItem>
                        <ListItem
                            component="a"
                            href={`//estore.${_.find(instances, { id: activeInstance }).instanceUrl}/configure`}
                            className={classes.listItem}
                            button>
                            <ListItemIcon>
                                <ShoppingCartIcon />
                            </ListItemIcon>
                            <ListItemText>ONLINE STORE</ListItemText>
                        </ListItem>
                        <ListItem
                            className={`${classes.listItem} ${this.isMenuOpened('productCatalog') ? 'active' : ''}`}
                            onClick={() => this.expandMenu('productCatalog')}
                            button>
                            <ListItemIcon>
                                <ListIcon />
                            </ListItemIcon>
                            <ListItemText>PRODUCT CATALOG</ListItemText>
                            {this.isMenuOpened('productCatalog') ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.isMenuOpened('productCatalog')} timeout="auto" unmountOnExit>
                            <List className={classes.expandableList}>
                                <ListItem button className={`${classes.nested} ${classes.listItem}`}>
                                    <ListItemIcon>
                                        <Home />
                                    </ListItemIcon>
                                    <ListItemText>Saved Products</ListItemText>
                                </ListItem>
                                <ListItem button className={`${classes.nested} ${classes.listItem}`}>
                                    <ListItemIcon>
                                        <ContactMail />
                                    </ListItemIcon>
                                    <ListItemText>Product Portfolio</ListItemText>
                                </ListItem>
                                <ListItem button className={`${classes.nested} ${classes.listItem}`}>
                                    <ListItemIcon>
                                        <Poll />
                                    </ListItemIcon>
                                    <ListItemText>Distribution Channels</ListItemText>
                                </ListItem>
                                <ListItem button className={`${classes.nested} ${classes.listItem}`}>
                                    <ListItemIcon>
                                        <SupervisorAccount />
                                    </ListItemIcon>
                                    <ListItemText>Clients</ListItemText>
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem
                            className={classes.listItem}
                            button>
                            <ListItemIcon>
                                <Settings />
                            </ListItemIcon>
                            <ListItemText>SETTINGS</ListItemText>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item>
                    <Divider className={classes.separator} />
                    <Typography variant="caption" className={classes.footerText}>
                        @ 2013-2019 ECCOMIX Networks, LLC
                    </Typography>
                    <Paper className={classes.footerPaper}>
                        <Grid container>
                            <Grid item xs>
                                <Typography variant="caption">Powered by
                                    <img className={classes.logo} src="/eccomix-logo.png" alt="eccomix logo"/>
                                    <sup>BETA</sup>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">v0.3</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );

        return (
            <Grid
                className={classes.drawerContainer}
                item>
                <Hidden mdUp>
                    <Drawer
                        PaperProps={{ elevation: 4 }}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{ paper: classes.drawerPaper }}
                        ModalProps={{ keepMounted: true }} >
                        { drawer }
                    </Drawer>
                </Hidden>
                <Hidden
                    smDown>
                    <Drawer
                        PaperProps={{ elevation: 16 }}
                        variant="permanent"
                        open
                        classes={{ paper: classes.drawerPaper }}>
                        { drawer }
                    </Drawer>
                </Hidden>
                <Dialog fullScreen open={dialogOpen} onClose={this.handleClose} className={classes.dialog}>
                    <Grid container item alignItems="center" direction="column" wrap="nowrap" className={classes.dialogContainer}>
                        <h1 className={classes.dialogHeader}>Select Customer Portal to Manage</h1>
                        {instances.map((instance) => (<div className={classes.instanceContainer} key={instance.id}>
                            <Button className={`${classes.linkButton} ${classes.instanceButton}`} onClick={() => this.changeInstance(instance.id)}>
                                <Paper className={classes.dialogPaper}>
                                    {instance.name}
                                </Paper>
                            </Button>
                        </div>))}
                    </Grid>
                    <div className={classes.closeDialogContainer}>
                        <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                            <CloseIcon className={classes.closeDialogIcon} />
                        </IconButton>
                    </div>
                </Dialog>
            </Grid>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SideDrawer);
