import AvaCAS from 'ava-cas-iframe';

const init = (doc) => new Promise((resolve, reject) => {
    const settings = {
        src: process.env.REACT_APP_CAS_SRC || undefined,
        postMessageSrc: process.env.REACT_APP_CAS_POST_MESSAGE_SRC || undefined,
        usersUrl: process.env.REACT_APP_CAS_URL,
        pusher: {
            id: process.env.REACT_APP_PUSHER_ID,
            cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        },
    };
    cas = new AvaCAS(doc, settings);
    cas.showLoader();
    cas.init()
        .then((data) => resolve({ cas, data }), (data) => {
            console.error('Failed to load CAS iFrame.');
            reject(new Error('CAS failed to load'));
        });
});

export default init;

export let cas;
