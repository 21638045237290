import React, { Component } from 'react';
import ReactFileReader from 'react-file-reader';
import filesize from 'filesize';

import { withPageBlocker } from 'AppContext';

import API from 'components/common/API';
import ConfigCard from 'components/common/ConfigCard';
import SectionHeader from 'components/common/SectionHeader';
import FileUploadErrorDialog from 'components/common/dialogs/FileUploadErrorDialog';

import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = (theme) => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
    }),
    logoPreview: {
        maxWidth: 68,
        maxHeight: 68,
        [theme.breakpoints.up('sm')]: {
            maxWidth: 160,
        },
    },
    imgContainer: {
        minWidth: 68,
        minHeight: 68,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            minWidth: 160,
        },
    },
    imgContainerEmpty: {
        borderRadius: theme.spacing.unit / 2,
        border: `1px dotted ${theme.palette.grey['700']}`,
    },
    deleteButton: {
        display: 'inline-block',
        paddingTop: 0,
        paddingBottom: 0,
        width: 37,
        height: 37,
        color: theme.palette.error.main,
        marginLeft: theme.spacing.unit * 2,
    },
});

class ImageSelector extends Component {

    state = {
        fileTooBig: false,
    };

    clearImage = () => {
        this.props.onChange({ imgSrc: '' });
    };

    handleFiles = (img) => {
        const { blocker, asBase64 = false, maxSize = false } = this.props;
        const imageFile = asBase64 ? img.fileList[0] : img[0];
        if (maxSize && imageFile.size > maxSize) {
            this.setState({ fileTooBig: true });
        }
        if (asBase64) {
            this.props.onChange({ imgSrc: img.base64});
        } else {
            blocker.block();
            API.uploadFile(imageFile)
                .then((imgSrc) => {
                    this.props.onChange({ imgSrc });
                })
                .finally(() => {
                    blocker.unblock();
                });
        }
    };

    render() {
        const { fileTooBig } = this.state;
        const {
            classes,
            className,
            imgSrc,
            label,
            onChange,
            onBlock,
            maxSize = false,
            asBase64 = false,
            ...rest
        } = this.props;

        const changeButton = (
            <div style={{ display: 'flex' }}>
                <ReactFileReader
                    base64={asBase64}
                    handleFiles={this.handleFiles}>
                    <Button
                        variant="outlined"
                        color="primary"><span>Change</span>
                    </Button>
                </ReactFileReader>
                {imgSrc && <Tooltip title="Clear image"><IconButton
                    color="inherit"
                    onClick={this.clearImage}
                    className={ classes.deleteButton }><DeleteIcon /></IconButton></Tooltip>}
            </div>
        );

        const preview = (
            <div
                className={ `${classes.imgContainer} ${imgSrc ? '' : classes.imgContainerEmpty}` }>
                {imgSrc && <img alt={`${label} preview`} className={ classes.logoPreview } src={ imgSrc }/>}
                {!imgSrc && <AddAPhotoIcon />}
            </div>
        );

        return (
            <ConfigCard
                {...rest}
                className={ className }>
                <SectionHeader
                    label={label}
                    secondaryContent={changeButton}
                    preview={preview}/>
                {
                    maxSize &&
                    <FileUploadErrorDialog
                        open={fileTooBig}
                        title="Selected image is too big."
                        content={`Maximum allowed file size is ${filesize(maxSize)}.`}
                        handleCancel={() => this.setState({ fileTooBig: false })}
                        handleClose={this.handleFiles} />
                }
            </ConfigCard>
        );
    }
};

export default withPageBlocker(withStyles(styles)(ImageSelector));
