import registerAvaCAS from './registerAvaCAS';
import { register } from './serviceWorker';

export default (document) => {
    return new Promise((resolve, reject) => {
        register({ onUpdate: () => console.log('New version ready') });
        registerAvaCAS(document)
            .then(resolve, reject);
    });
};
