import React, { Component } from 'react';

import ExternalLoginProvider, { externalLoginProviders } from './ExternalLoginProvider';
import ImageSelector from './ImageSelector';
import TextSelector from './TextSelector';

class ConfigurationPanel extends Component {

    handleProviderConfig = (provider) => (config) => {
        this.props.onProvidersChange({ [provider]: config });
    };

    handleFaviconChange = ({ imgSrc: favicon }) => {
        this.props.onChange({ favicon });
    };

    handleTitleText = ({ line1: title }) => {
        this.props.onChange({ title });
    };

    render() {
        const { themeData: { favicon, title }, login } = this.props;

        return (
            <React.Fragment>
                <TextSelector
                    label="Page title:"
                    line1={title}
                    line1Title="Title"
                    multiline={false}
                    onChange={this.handleTitleText}/>
                <ImageSelector
                    asBase64={true}
                    label="Favicon"
                    imgSrc={favicon}
                    maxSize={process.env.REACT_APP_FAVICON_MAX_SIZE}
                    onChange={this.handleFaviconChange}/>
                { externalLoginProviders.map((loginProvider) =>
                    <ExternalLoginProvider
                        key={loginProvider}
                        onChange={this.handleProviderConfig(loginProvider)}
                        config={login[loginProvider]}
                        provider={loginProvider} />
                ) }
            </React.Fragment>
        );
    }
}

export default ConfigurationPanel;
