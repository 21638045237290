import React, { Component } from 'react';

import ButtonSelector from './ButtonSelector';
import ImageSelector from './ImageSelector';
import TextSelector from './TextSelector';

const DUAL_LINE = '\n';

class LoginPanel extends Component {

    handleLogoChange = ({ imgSrc: logoPath }) => {
        this.props.onChange({ logoPath });
    };

    handleMobileLogoChange = ({ imgSrc: mobileLogoPath }) => {
        this.props.onChange({ mobileLogoPath });
    };

    handleFooterText = ({ line1, line2 }) => {
        const [currentLine1, currentLine2] = (this.props.themeData.footer.text || '').split(DUAL_LINE);
        this.props.onChange({ footer: { text: [
            line1 === undefined ? currentLine1 : line1,
            line2 === undefined ? currentLine2 : line2,
        ].join(DUAL_LINE) } });
    };

    handleButtonChange = ({ color }) => {
        this.props.onChange({ accentColor: color });
    };

    render() {
        const { themeData: { logoPath, mobileLogoPath, accentColor, footer: { text: footerText } } } = this.props;

        const [line1 = '', line2 = ''] = footerText.split(DUAL_LINE);

        return (
            <React.Fragment>
                <TextSelector
                    label="Footer text:"
                    elevation={2}
                    line1={line1}
                    line2={line2}
                    onChange={this.handleFooterText}/>
                <ImageSelector
                    label="Footer logo:"
                    imgSrc={logoPath}
                    onBlock={this.props.onBlock}
                    onChange={this.handleLogoChange} />
                <ButtonSelector
                    onChange={this.handleButtonChange}
                    color={accentColor} />
                <ImageSelector
                    label="Mobile logo on top:"
                    imgSrc={mobileLogoPath}
                    onBlock={this.props.onBlock}
                    onChange={this.handleMobileLogoChange}/>
            </React.Fragment>
        );
    }
}

export default LoginPanel;
