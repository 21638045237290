import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

const positionOptions = [
    'center',
    'top left',
    'top right',
    'bottom left',
    'bottom right',
];

const styles = (theme) => ({
    paper: {
        padding: theme.spacing.unit,
        width: `calc(100% - ${theme.spacing.unit}px)`,
        minHeight: 38,
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
            display: 'inline-block',
        },
    },
    select: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            minWidth: theme.spacing.unit * 20,
            maxWidth: theme.spacing.unit * 20,
        },
    },
    selectPreview: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: theme.spacing.unit * 4,
    },
    menuItem: {
        textTransform: 'capitalize',
        display: 'flex',
        justifyContent: 'space-between',
    },
});

class CssPositionSelect extends Component {
    render() {
        const { classes, position, inputProps, className = '' } = this.props;
        return (
            <Paper className={`${classes.paper} ${className}`} elevation={2}>
                <Select
                    className={ classes.select }
                    SelectDisplayProps={{ className: classes.selectPreview }}
                    inputProps={{ ...inputProps }}
                    value={position}
                    disableUnderline={true}
                    onChange={this.props.onChange}>
                    {positionOptions.map((positionOption) => (
                    <MenuItem className={ classes.menuItem } key={positionOption} value={positionOption}>
                        <Typography variant="caption">Position:</Typography>
                        <Typography variant="caption" className="capitalize">{positionOption}</Typography>
                    </MenuItem>))}
                </Select>
            </Paper>
        );
    };
}

export default withStyles(styles)(CssPositionSelect);

export { positionOptions };
