import React, { Component } from 'react';
import _ from 'lodash';
import deepAssign from 'deep-assign';

import ConfigurationPanel from './ConfigurationPanel';
import LoginPanel from './LoginPanel';
import WelcomePage from './WelcomePage';

import { withStyles } from '@material-ui/core/styles';

import {
    Button,
    Fab,
    Grid,
    Hidden,
    Tabs,
    Tab,
    Toolbar,
    Typography,
} from '@material-ui/core';

import { Check as CheckIcon } from '@material-ui/icons';

const styles = (theme) => ({
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    card: {
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 16,
    },
    contentTitle: {
        flex: 1,
    },
    fabButton: {
        position: 'fixed',
        right: theme.spacing.unit * 10,
        marginTop: theme.spacing.unit * 4,
        zIndex: 1,
    },
    fabButtonMobile: {
        position: 'fixed',
        right: theme.spacing.unit * 2,
        bottom: theme.spacing.unit * 2,
        zIndex: 1,
    },
    tabContent: {
        padding: `0 ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    },
});

class LoginPage extends Component {
    state = {
        changed: false,
        activeTab: 0,
        currentData: null,
        currentLoginData: null,
    };

    handleChangeTab = (event, activeTab) => {
        this.setState({ activeTab });
    };

    handleLoginCustomization = (customizationData = {}) => {
        console.log('LoginPage.handleLoginCustomization', customizationData);
        const { currentData } = this.state;
        deepAssign(currentData, { login: customizationData });
        this.setState({ currentData });
    };

    handleConfigurationCustomization = (customizationData = {}) => {
        console.log('LoginPage.handleConfigurationCustomization', customizationData);
        const { currentData } = this.state;
        deepAssign(currentData, customizationData);
        this.setState({ currentData });
    };

    handleExternalProvidersUpdate = (login = {}) => {
        console.log('LoginPage.handleExternalProvidersUpdate', login);
        const { currentLoginData } = this.state;
        deepAssign(currentLoginData, login);
        this.setState({ currentLoginData });
    };

    handleSave = () => {
        const instanceChange = {};
        if (!_.isEqual(this.props.data, this.state.currentData)) {
            instanceChange.guiSettings = { theme: this.state.currentData };
        }
        if (!_.isEqual(this.props.login, this.state.currentLoginData)) {
            instanceChange.login = this.state.currentLoginData;
        }
        if (!_.isEmpty(instanceChange)) {
            this.props.onChange(instanceChange);
        }
    };

    componentDidMount = (props) => {
        this.setState({
            currentData: _.cloneDeep(this.props.data),
            currentLoginData: _.cloneDeep(this.props.login),
        });
    };

    componentDidUpdate = (prevProps) => {
        const stateUpdate = {};
        if (!_.isEqual(prevProps.data, this.props.data)) {
            stateUpdate.currentData = _.cloneDeep(this.props.data);
        }
        if (!_.isEqual(prevProps.login, this.props.login)) {
            stateUpdate.currentLoginData = _.cloneDeep(this.props.login);
        }
        if (!_.isEmpty(stateUpdate)) {
            this.setState(stateUpdate);
        }
    };

    render() {
        const { activeTab, currentData, currentLoginData } = this.state;
        const { classes, data, login } = this.props;

        if (!currentData && !currentLoginData) {
            return (<div>LOADING</div>);
        }

        // console.log(currentData);
        // console.log(currentLoginData);

        const loginUnchanged = _.isEqual(data, currentData) && _.isEqual(login, currentLoginData);

        return (
            <React.Fragment>
                <Grid  direction="column" container item xs>
                    <Hidden xsDown>
                        <Toolbar
                            className={classes.subheader}>
                            <Typography
                                className={ classes.contentTitle }
                                variant="h6">Customize Login Page</Typography>
                            <Fab
                                onClick={this.handleSave}
                                className={ classes.fabButton }
                                color="secondary"
                                disabled={ loginUnchanged }>
                                <CheckIcon />
                            </Fab>
                        </Toolbar>
                    </Hidden>
                    <Tabs
                        onChange={ this.handleChangeTab }
                        value={ activeTab }
                        indicatorColor="primary"
                        textColor="primary"
                        centered>
                        <Tab
                            label="Welcome Page"></Tab>
                        <Tab
                            label="Login Panel"></Tab>
                        <Tab
                            label="Configuration"></Tab>
                    </Tabs>
                    <div
                        className={ classes.tabContent }>
                        { activeTab === 0 && (<WelcomePage onChange={this.handleLoginCustomization} themeData={ currentData.login }/>) }
                        { activeTab === 1 && (<LoginPanel onChange={this.handleLoginCustomization} themeData={ currentData.login }/>) }
                        { activeTab === 2 && (<ConfigurationPanel
                            onProvidersChange={this.handleExternalProvidersUpdate}
                            onChange={this.handleConfigurationCustomization}
                            themeData={ currentData }
                            login={ currentLoginData }/>) }
                        <Hidden smUp>
                            {!loginUnchanged && <Button
                                onClick={this.handleLoginSave}
                                className={ classes.fabButtonMobile }
                                color="secondary"
                                disabled={loginUnchanged}
                                variant="fab">
                                <CheckIcon />
                            </Button>}
                        </Hidden>
                    </div>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(styles, { withTheme: true })(LoginPage);
