import React, { Component } from 'react';

import colors from 'helpers/colors';

import ConfigCard from 'components/common/ConfigCard';
import CssPositionSelect from 'components/common/CssPositionSelect';
import SectionHeader from 'components/common/SectionHeader';
import ColorPickerDialog from 'components/common/dialogs/ColorPickerDialog';

import { withStyles } from '@material-ui/core/styles';

import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const styles = (theme) => ({
    root: theme.mixins.gutters({
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    }),
    gutter: {
        margin: theme.spacing.unit,
    },
    paperContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        margin: `0 ${-theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    paper: {
        padding: theme.spacing.unit,
        margin: theme.spacing.unit,
        width: `calc(100% - ${theme.spacing.unit}px)`,
        minHeight: 38,
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
            display: 'inline-block',
        },
    },
    select: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            minWidth: theme.spacing.unit * 20,
            maxWidth: theme.spacing.unit * 20,
        },
    },
    selectPreview: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: theme.spacing.unit * 4,
    },
    menuItem: {
        textTransform: 'capitalize',
        display: 'flex',
        justifyContent: 'space-between',
    },
    colorPicker: {
        cursor: 'pointer',
        display: 'flex',
        position: 'relative',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.spacing.unit / 2,
        [theme.breakpoints.up('sm')]: {
            minWidth: theme.spacing.unit * 20,
            maxWidth: theme.spacing.unit * 20,
        },
    },
    colorPickerPreview: {
        borderRadius: '50%',
        display: 'inline-block',
        width: theme.spacing.unit * 2,
        height: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 3,
        borderStyle: 'solid',
        borderWidth: 1,
    },
    dropdownIcon: {
        position: 'absolute',
        right: 0,
        top: 'calc(50% - 12px)',
        color: theme.palette.text.secondary,
    },
});

const fontSizeOptions = [12, 14, 16, 18, 20, 22, 24, 26, 30];
const fontWeightOptions = [100, 300, 400, 500];

const inputMaxLength = 60;

class TextSelector extends Component {
    state = {
        line1Length: '0',
        line2Length: '0',
        dialogOpen: false,
    };

    handleChange = ({ target: { name, value } }) => {
        this.props.onChange({ [name]: value });
    };

    toggleDialog = () => {
        this.setState({ dialogOpen: !this.state.dialogOpen });
    };

    handleDialogClose = ({ color }) => {
        this.handleChange({ target: { name: 'color', value: color }});
        this.toggleDialog();
    };

    static getDerivedStateFromProps = (props, state) => {
        Object.assign(state, {
            line1Length: (props.line1 || '').length,
            line2Length: (props.line2 || '').length,
        });
        return state;
    };

    render() {

        const { line1Length, line2Length, dialogOpen } = this.state;
        const { classes,
            className,
            label,
            disabled,
            themeData,
            line1,
            line2,
            fontSize,
            fontWeight,
            position,
            onChange,
            color,
            line1Title = 'Line 1',
            line2Title = 'Line 2',
            multiline = true,
            ...rest
        } = this.props;

        const showColor = color !== undefined;
        const showFontSize = fontSize !== undefined;
        const showFontWeight = fontWeight !== undefined;
        const showPosition = position !== undefined;

        const fontColorStyles = {
            backgroundColor: color,
            borderColor: color && colors.getContrastColor(color),
        };

        const colorSelect = (
            <Paper className={classes.paper} elevation={2}>
                <div
                    onClick={this.toggleDialog}
                    className={classes.colorPicker}>
                    <Typography variant="caption">Color:</Typography>
                    <span
                        className={classes.colorPickerPreview}
                        style={fontColorStyles}></span>
                    <ArrowDropDownIcon className={ classes.dropdownIcon } />
                </div>
                {dialogOpen &&  <ColorPickerDialog
                    open={dialogOpen}
                    color={color}
                    onCancel={this.toggleDialog}
                    onClose={this.handleDialogClose}/>}
            </Paper>);

        const fontSizeSelect = (
            <Paper className={classes.paper} elevation={2}><Select
                className={ classes.select }
                SelectDisplayProps={{ className: classes.selectPreview }}
                inputProps={{ name: 'fontSize' }}
                value={fontSize}
                disableUnderline={true}
                onChange={this.handleChange}>
                {fontSizeOptions.map((fontSizeOption) => (
                    <MenuItem className={ classes.menuItem } key={fontSizeOption} value={fontSizeOption}>
                        <Typography variant="caption">Size:</Typography>
                        <span style={{ fontSize: fontSizeOption }}>{fontSizeOption}pt</span>
                    </MenuItem>))}
            </Select></Paper>);

        const fontWeightSelect = (
            <Paper className={classes.paper} elevation={2}><Select
                className={ classes.select }
                SelectDisplayProps={{ className: classes.selectPreview }}
                inputProps={{ name: 'fontWeight' }}
                value={fontWeight}
                disableUnderline={true}
                onChange={this.handleChange}>
                {fontWeightOptions.map((fontWeightOption) => (
                    <MenuItem className={ classes.menuItem } key={fontWeightOption} value={fontWeightOption}>
                        <Typography variant="caption">Weight:</Typography>
                        <Typography style={{ fontWeight: fontWeightOption }} variant="caption" className="capitalize">{fontWeightOption}</Typography>
                    </MenuItem>))}
            </Select></Paper>);

        return (
            <ConfigCard
                {...rest}
                className={ `${className}` }>
                <SectionHeader label={label}/>
                <Divider />
                <div className={classes.root}>
                    <form className={classes.container} noValidate autoComplete="off">
                        <div
                            className={classes.paperContainer}>
                            { showColor && colorSelect }
                            { showFontSize && fontSizeSelect }
                            { showFontWeight && fontWeightSelect }
                            { showPosition && <CssPositionSelect inputProps={{ name: 'position' }} className={classes.gutter} position={position} onChange={this.handleChange} /> }
                        </div>
                        <TextField
                            disabled={!!disabled}
                            value={line1}
                            label={line1Title}
                            onChange={this.handleChange}
                            inputProps={{ maxLength: inputMaxLength, name: 'line1' }}
                            FormHelperTextProps={{style: { textAlign: 'right'} }}
                            helperText={ `${line1Length}/${inputMaxLength}` }
                            fullWidth />
                        {multiline &&
                            <TextField
                                disabled={!!disabled}
                                value={line2}
                                label={line2Title}
                                onChange={this.handleChange}
                                inputProps={{ maxLength: inputMaxLength, name: 'line2' }}
                                FormHelperTextProps={{style: { textAlign: 'right'} }}
                                helperText={ `${line2Length}/${inputMaxLength}` }
                                fullWidth />
                        }
                    </form>
                </div>
            </ConfigCard>
        );
    }
};

export default withStyles(styles)(TextSelector);
