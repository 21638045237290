import React, { Component } from 'react';

import ImageUploader from 'components/common/ImageUploader';

import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = (theme) => ({

});

class FileUploadErrorDialog extends Component {
    render() {
        return <Dialog
            fullScreen={this.props.fullScreen}
            open={this.props.open}
            onClose={this.props.handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {this.props.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.handleCancel} color="primary">
                    Cancel
                </Button>
                <ImageUploader
                    handleFiles={this.props.handleClose}>
                    <Button variant="raised" color="primary" autoFocus>
                        Select new
                    </Button>
                </ImageUploader>
            </DialogActions>
        </Dialog>};
}

export default withMobileDialog()(withStyles(styles)(FileUploadErrorDialog));
