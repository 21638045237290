import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

const styles = (theme) => ({
    card: {
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 16,
    },
});

class ConfigCard extends Component {
    render() {
        const { children, classes } = this.props;
        return <Paper
            className={ classes.card }
            elevation={2}>{children}</Paper>;
    };
}

export default withStyles(styles)(ConfigCard)
