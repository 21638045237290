import React, { Component } from 'react';
import gradientParser from 'gradient-parser';
import tinycolor from 'tinycolor2';
import _ from 'lodash';

import colors from 'helpers/colors';

import BackgroundImage from 'components/common/BackgroundImage';
import BackgroundPreview from 'components/common/BackgroundPreview';
import ConfigCard from 'components/common/ConfigCard';
import PalettePicker from 'components/common/PalettePicker';
import SectionHeader from 'components/common/SectionHeader';

import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
    root: {
        padding: theme.spacing.unit * 2,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 3,
            paddingRight: theme.spacing.unit * 3,
        },
    },
    colorPreview: {
        width: theme.spacing.unit * 8,
        height: theme.spacing.unit * 8,
        borderRadius: theme.spacing.unit / 2,
    },
    dialogContentTop: {
        padding: `${theme.spacing.unit * 2}px`,
        flex: 'none',
    },
    dialogContentBottom: {
        padding: theme.spacing.unit * 2,
    },
    flex: {
        flex: 1,
    },
});

const backgroundModes = [
    {
        id: 'solid',
        label: 'Color',
    },
    {
        id: 'gradient',
        label: 'Gradient',
    },
    {
        id: 'image',
        label: 'Image',
    },
];

backgroundModes.SOLID = backgroundModes[0].id;
backgroundModes.GRADIENT = backgroundModes[1].id;
backgroundModes.IMAGE = backgroundModes[2].id;

const gradientOrientations = [
    'right bottom',
    'bottom',
    'left bottom',
    'left',
    'left top',
    'top',
    'right top',
    'right',
];

const toAngle = (gradientDirection = '') => {
    switch (gradientDirection.toLowerCase()) {
        case 'right bottom':
            return '315deg';
        case 'left bottom':
            return '45deg';
        case 'left':
            return '90deg';
        case 'left top':
            return '135deg';
        case 'top':
            return '180deg';
        case 'right top':
            return '225deg';
        case 'right':
            return '270deg';
        default:
            return '0deg';
    }
};

class BackgroundSelector extends Component {
    state = {
        dialogOpen: false,
        backgroundMode: backgroundModes.SOLID,
        solidBackground: colors.defaultColor,
        gradientBackground: BackgroundSelector.getGradient(colors.defaultColor, colors.defaultColor, gradientOrientations[0]),
        imageBackground: '',
    };

    handleModeChange = (event, backgroundMode) => {
        this.props.onChange({ background: this.state[`${backgroundMode}Background`]});
    };

    handlePrimaryColorChange = (primaryColor) => {
        // console.log('BackgroundSelector.handlePrimaryColorChange', primaryColor);
        this.handleBackgroundChange({ primaryColor });
    };

    handleSecondaryColorChange = (secondaryColor) => {
        // console.log('BackgroundSelector.handleSecondaryColorChange', secondaryColor);
        this.handleBackgroundChange({ secondaryColor });
    };

    handleGradientOrientationChange = () => {
        const { gradientOrientation } = this.getColors();
        if (gradientOrientation) {
            const gradientPosition = _.indexOf(gradientOrientations, gradientOrientation);
            this.handleBackgroundChange({ gradientOrientation: gradientOrientations[gradientPosition + 1] || gradientOrientations[0] });
        }
    };

    handleBackgroundChange = ({ background, primaryColor, secondaryColor, gradientOrientation, backgroundMode = this.state.backgroundMode } = {}) => {
        // console.log('BackgroundSelector.handleBackgroundChange', primaryColor, secondaryColor);
        const colors = this.getColors(backgroundMode, primaryColor);
        const backgroundStyle = {};
        if (backgroundMode === backgroundModes.SOLID) {
            Object.assign(backgroundStyle, { background: primaryColor || colors.primaryColor});
        } else if (backgroundMode === backgroundModes.GRADIENT) {
            Object.assign(backgroundStyle, { background: BackgroundSelector.getGradient(
                primaryColor || colors.primaryColor,
                secondaryColor || colors.secondaryColor,
                gradientOrientation || colors.gradientOrientation,
            )});
        } else {
            Object.assign(backgroundStyle, { background });
        }
        this.setState({ [`${backgroundMode}Background`]: backgroundStyle.background });
        this.props.onChange(backgroundStyle);
    };

    getBackgroundMode = (background) => {
        if (!background.match(/url\(/i) && !!background) {
            try {
                gradientParser.parse(background);
                return backgroundModes.GRADIENT;
            } catch (error) {
                return backgroundModes.SOLID;
            }
        }
        return backgroundModes.IMAGE;
    };

    componentDidMount = () => {
        const { props } = this;
        const backgroundMode = this.getBackgroundMode(props.background);
        this.setState({ backgroundMode, [`${backgroundMode}Background`]: props.background });
    };

    componentDidUpdate = (props, state) => {
        const backgroundMode = this.getBackgroundMode(this.props.background);
        if (backgroundMode !== this.state.backgroundMode) {
            this.setState({ backgroundMode });
        }
    };

    getColors = (backgroundMode = this.state.backgroundMode, primaryColor = this.props.background) => {
        const { background } = this.props;
        const update = { primaryColor: primaryColor || colors.asArray()[0].main };
        try {
            const [{ orientation: { value: gradientOrientation }, colorStops: [startColor, endColor] }] = gradientParser.parse(background);
            if (backgroundMode === backgroundModes.GRADIENT) {
                Object.assign(update, {
                    gradientOrientation,
                    primaryColor: tinycolor(startColor.value).toHexString(),
                    secondaryColor: tinycolor(endColor.value).toHexString(),
                });
            } else {
                Object.assign(update, {
                    primaryColor: tinycolor(startColor.value).toHexString(),
                });
            }
        } catch (error) {
            Object.assign(update, {
                primaryColor: tinycolor(primaryColor).toHexString() || colors.toPaletteColor(background).main,
            });
        }
        // console.log(`BackgroundSelector.getColors ${background} => ${JSON.stringify(update)}`);
        return update;
    };

    static getGradient = (primaryColor, secondaryColor, gradientOrientation = gradientOrientations[0]) => {
        return `linear-gradient(to ${gradientOrientation}, ${primaryColor}, ${secondaryColor || primaryColor})`;
    };

    handleDialogCancel = () => {
        const { backgroundMode } = this.state.saved;
        this.props.onChange({ background: this.state.saved[`${backgroundMode}Background`]});
        this.setState({ saved: undefined });
        this.toggleDialog();
    };

    handleDialogOK = () => {
        this.toggleDialog();
    };

    handleDialogOnEnter = () => {
        const { backgroundMode, solidBackground, gradientBackground, imageBackground } = this.state;
        this.setState({ saved: { backgroundMode, solidBackground, gradientBackground, imageBackground }});
    };

    toggleDialog = () => {
        this.setState({ dialogOpen: !this.state.dialogOpen });
    };

    render() {
        const { dialogOpen, backgroundMode } = this.state;
        const { background, classes, className, themeData, onChange, width, ...rest } = this.props;
        const { primaryColor, secondaryColor, gradientOrientation } = this.getColors(backgroundMode);

        const previewStyle = { background };

        // console.log('BackgroundSelector.render', background);

        const colorPicker = (<div>
            <Grid container>
                <Grid sm item>
                    <PalettePicker
                        label={`Main color`}
                        color={ primaryColor }
                        onChange={ this.handlePrimaryColorChange } /></Grid>
                    {backgroundMode === backgroundModes.GRADIENT && <Grid sm item>
                    <PalettePicker
                        label={`Secondary color`}
                        color={ secondaryColor }
                        onChange={ this.handleSecondaryColorChange } /></Grid>}
            </Grid>
        </div>);

        const preview = (hideSwap = false) => (
            <BackgroundPreview
                className={ classes.dialogPreview }
                onClick={this.handleGradientOrientationChange}
                showSwapButton={backgroundMode === backgroundModes.GRADIENT && !hideSwap}
                swapButtonRotation={toAngle(gradientOrientation)}
                backgroundStyle={previewStyle} />
        );

        const colorDialog = (
            <Dialog
                onEnter={this.handleDialogOnEnter}
                fullScreen={true}
                open={dialogOpen}>
                <AppBar
                    position="static">
                    <Toolbar>
                        <Typography variant="h6" color="inherit" className={classes.flex}>Edit background</Typography>
                        <IconButton color="inherit" onClick={this.handleDialogCancel} className="toolbar-icon-right" aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent
                    className={ classes.dialogContentTop }>
                    <RadioGroup
                        value={ backgroundMode }
                        onChange={ this.handleModeChange }
                        row>
                        { backgroundModes.map((colorOption, key) => (<FormControlLabel key={key} value={ colorOption.id } control={<Radio />} label={ colorOption.label } />))}
                    </RadioGroup>
                    { backgroundMode === backgroundModes.IMAGE ? '' : preview() }
                </DialogContent>
                <Divider />
                <DialogContent
                    className={ classes.dialogContentBottom }>
                {
                    backgroundMode === backgroundModes.IMAGE ?
                    <BackgroundImage
                        onChange={this.handleBackgroundChange}
                        background={background} />
                    :
                    colorPicker
                }
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={this.handleDialogCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleDialogOK} color="primary">
                        Select
                    </Button>
                </DialogActions>
            </Dialog>
        );

        const secondaryContent = (
            <div>
                <Hidden xsDown>
                    <RadioGroup
                        value={ backgroundMode }
                        onChange={ this.handleModeChange }
                        row>
                        { backgroundModes.map((colorOption, key) => (<FormControlLabel key={key} value={ colorOption.id } control={<Radio color="primary" />} label={ colorOption.label } />))}
                    </RadioGroup>
                </Hidden>
                <Hidden smUp>
                    <Button
                        onClick={this.toggleDialog}
                        variant="outlined"
                        color="primary">Change</Button>
                </Hidden>
            </div>
        );

        return (
            <ConfigCard
                {...rest}>
                <SectionHeader
                    label="Background:"
                    preview={isWidthDown('sm', width) || backgroundMode !== backgroundModes.IMAGE ? preview(isWidthDown('sm', width)) : <div></div>}
                    secondaryContent={secondaryContent}/>
                <Hidden xsDown>
                    <Divider />
                    <div className={classes.root}>
                        {backgroundMode !== backgroundModes.IMAGE && colorPicker}
                        {backgroundMode === backgroundModes.IMAGE && <BackgroundImage
                            onChange={this.handleBackgroundChange}
                            background={background} />}
                    </div>
                </Hidden>
                <Hidden smUp>{colorDialog}</Hidden>
            </ConfigCard>
        );
    };
};

export default withWidth()(withStyles(styles)(BackgroundSelector));
