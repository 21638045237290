import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SwapVertIcon from '@material-ui/icons/SwapVert';

const styles = (theme) => ({
    previewContainer: {
        position: 'relative',
        flex: `0 1 ${theme.spacing.unit * 8}px`,
        [theme.breakpoints.up('sm')]: {
            position: 'static',
            flex: 0,
        },
        display: 'flex',
        alignItems: 'center',
    },
    preview: {
        minWidth: theme.spacing.unit * 8,
        width: '100%',
        height: theme.spacing.unit * 8,
        borderRadius: theme.spacing.unit / 2,
        border: `1px solid ${theme.palette.grey['700']}`,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 8,
        },
    },
    gradientButton: {
        marginRight: theme.spacing.unit * 2,
    },
});

class BackgroundPreview extends Component {
    render() {
        const { className, backgroundStyle, showSwapButton = false, swapButtonRotation = '0deg', classes } = this.props;
        return (
            <div
                className={ `${className}  ${classes.previewContainer}` }>
                {
                    showSwapButton &&
                    <IconButton
                        variant="outlined"
                        onClick={this.props.onClick}
                        className={ classes.gradientButton }>
                        <SwapVertIcon
                            className={ classes.gradientButtonIcon }
                            style={{transform: `rotate(${swapButtonRotation})`}} />
                    </IconButton>
                }
                <div
                    className={ classes.preview }
                    style={backgroundStyle}></div>
            </div>
        );
    }
}

export default withStyles(styles)(BackgroundPreview);
