import React, { Component } from 'react';

import colors from 'helpers/colors';

import { withStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';

const styles = (theme) => ({
    colorPickerBg: {
        backgroundColor: 'transparent',
        cursor: 'pointer',
        borderRadius: '50%',
        padding: theme.spacing.unit * 0.5,
        margin: theme.spacing.unit * 0.5,
        transition: 'background-color 250ms ease-in-out',
        display: 'inline-block',
    },
    colorPicker: {
        cursor: 'pointer',
        borderRadius: '50%',
        display: 'inline-block',
        border: `1px solid ${theme.palette.grey['700']}`,
        position: 'relative',
    },
    selected: {
        backgroundColor: theme.palette.grey['300'],
    },
    selectedIcon: {
        position: 'absolute',
        top: theme.spacing.unit * 0.375,
        right: theme.spacing.unit * 0.375,
    },
});

class ColorButton extends Component {
    render() {
        const { selected, classes, color, size = 40 } = this.props;

        const bgStyle = {
            width: size,
            height: size,
        };

        const style = {
            backgroundColor: color,
            width: size - 8,
            height: size - 8,
        };

        return (
            <div
                className={ `${classes.colorPickerBg} ${selected ? classes.selected : ''}` }
                style={ bgStyle }>
                <div
                    style={ style }
                    onClick={() => this.props.onChange(color)}
                    className= {classes.colorPicker }>
                    { selected && (<DoneIcon
                        style={{ color: colors.getContrastColor(color), }}
                        className={ classes.selectedIcon } />) }
                    </div>
            </div>
        );
    }
}

export default withStyles(styles)(ColorButton);
