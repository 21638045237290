import React, { Component } from 'react';
import tinycolor from 'tinycolor2';

import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import ConfigCard from 'components/common/ConfigCard';
import PalettePicker from 'components/common/PalettePicker';
import SectionHeader from 'components/common/SectionHeader';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
    root: theme.mixins.gutters({
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    }),
    dialogContentTop: {
        paddingTop: theme.spacing.unit * 2,
        flex: 'none',
        '& > button': {
            width: '100%',
        },
    },
    flex: {
        flex: 1,
    },
});

class ButtonSelector extends Component {
    state = {
        dialogOpen: false,
        customTheme: {},
    };
    handleDialogCancel = () => {
        const { savedColor } = this.state;
        this.handleColorChange(savedColor);
        this.toggleDialog();
    };
    handleDialogOK = () => {
        this.toggleDialog();
    };
    handleDialogOnEnter = () => {
        const { color } = this.props;
        this.setState({ savedColor: color });
    };
    toggleDialog = () => {
        this.setState({ dialogOpen: !this.state.dialogOpen });
    }
    handleColorChange = (color) => {
        // console.log('ButtonSelector.handleColorChange', color);
        this.props.onChange({ color });
    };
    static getTheme = (main) => createMuiTheme({
        palette: { primary: { main }, },
    });

    render() {
        const { dialogOpen } = this.state;
        const { classes, className, color, ...rest } = this.props;

        const customTheme = ButtonSelector.getTheme(color);

        const colorPicker = (<PalettePicker
            label="Main color"
            color={ tinycolor(color).toHexString() }
            onChange={ this.handleColorChange } />);

        const colorDialog = (<Dialog
            onEnter={this.handleDialogOnEnter}
            fullScreen={true}
            open={dialogOpen}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" color="inherit" className={classes.flex}>Edit button:</Typography>
                    <IconButton color="inherit" onClick={this.handleDialogCancel} className="toolbar-icon-right" aria-label="Close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent className={ classes.dialogContentTop }>
                <MuiThemeProvider theme={customTheme}>
                    <Button
                        variant="contained"
                        color="primary">
                        <span>Sample Button</span>
                    </Button>
                </MuiThemeProvider>
            </DialogContent>
            <Divider />
            <DialogContent className={ classes.dialogContent }>
                {colorPicker}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleDialogCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={this.handleDialogOK} color="primary">
                    Select
                </Button>
            </DialogActions>
        </Dialog>);

        const secondaryContent = (
            <Hidden smUp>
                <Button
                    variant="outlined"
                    onClick={this.toggleDialog}
                    color="primary">Change</Button>
            </Hidden>
        );

        const preview = (
            <MuiThemeProvider theme={customTheme}>
                <Button
                    className={ classes.buttonPreview }
                    variant="contained"
                    color="primary">
                    <span>Sample Button</span>
                </Button>
            </MuiThemeProvider>
        );

        return (
            <ConfigCard
                {...rest}
                className={className}>
                <SectionHeader
                    label="Buttons color:"
                    preview={preview}
                    secondaryContent={secondaryContent}/>
                <Hidden xsDown><Divider /><div className={ classes.root }>{colorPicker}</div></Hidden>
                <Hidden smUp>{colorDialog}</Hidden>
            </ConfigCard>
        );
    }
};

export default withStyles(styles)(ButtonSelector);
