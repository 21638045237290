import _ from 'lodash';
import tinycolor from 'tinycolor2';
import * as muiColors from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const defaultVariant = '500';

const defaultTheme = createMuiTheme();

const variants = [
    '50',
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
    'A100',
    'A200',
    'A400',
    'A700',
];

const colorsArray = [
    'red',
    'pink',
    'purple',
    'deepPurple',
    'indigo',
    'blue',
    'lightBlue',
    'cyan',
    'teal',
    'green',
    'lightGreen',
    'lime',
    'yellow',
    'amber',
    'orange',
    'deepOrange',
    'brown',
    'grey',
    'blueGrey',
    'common',
].map((colorName) => {
    const paletteColor = muiColors[colorName];
    const color = {
        id: colorName,
        main: tinycolor(paletteColor[defaultVariant]).toHexString(),
        variants: _.uniq(variants.map((variant) => tinycolor(paletteColor[variant]).toHexString())),
    }
    if (colorName === 'common') {
        color.main = tinycolor(paletteColor.black).toHexString();
        color.variants = [color.main, tinycolor(paletteColor.white).toHexString()];
    }
    return color;
});

const colorsHash = {};

_.forEach(colorsArray, (color) => {
    _.forEach(color.variants, (variant) => {
        const normalizedColor = tinycolor(variant).toHexString();
        colorsHash[normalizedColor] = colorsHash[normalizedColor] || color;
    });
});

export default {
    muiColors,
    colors: _.keyBy(colorsArray, 'id'),
    defaultColor: colorsArray[0].main,
    normalize(color) {
        return tinycolor(color).toHexString();
    },
    asArray() {
        return colorsArray;
    },
    cleanup() {
        _.forEach(colorsArray, (color) => {
            if (color.custom) {
                delete colorsHash[color.main];
            }
        });
        _.remove(colorsArray, { custom: true });
    },
    getContrastColor(color) {
        return defaultTheme.palette.getContrastText(color);
    },
    toPaletteColor(colorValue) {
        const normalizedColor = tinycolor(colorValue).toHexString();
        if (!colorsHash[normalizedColor]) {
            colorsHash[normalizedColor] = {
                id: normalizedColor,
                main: normalizedColor,
                variants: [normalizedColor],
                custom: true,
            };
            colorsArray.push(colorsHash[normalizedColor]);
        }
        return colorsHash[normalizedColor];
    },
};
