import React, { Component } from 'react';
import _ from 'lodash';

import ConfigCard from 'components/common/ConfigCard';
import SectionHeader from 'components/common/SectionHeader';

import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const styles = (theme) => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
    }),
    switchBase: {
        height: 'auto',
    },
    form: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        }
    },
    textField: {
        [theme.breakpoints.up('sm')]: {
            flex: 1,
            '&:first-child': {
                marginRight: theme.spacing.unit * 2,
            },
            '&:last-child': {
                marginLeft: theme.spacing.unit * 2,
            },
        },
    },
});

const externalLoginProviders = [
    'facebook',
    'google',
];

class ExternalLoginProvider extends Component {

    state = {
        showPassword: false,
    };

    handleChange = ({ target: { name, value } }, checked = false) => {
        this.props.onChange({ [name]: name === 'enabled' ? checked : value });
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleMouseDownPassword = (event) => event.preventDefault();

    render() {

        const {
            width,
            classes,
            provider = externalLoginProviders[0],
            config,
        } = this.props;

        const {
            showPassword,
        } = this.state;

        const isMobile = isWidthDown('xs', width);

        const configValid = config.appId && config.secret;

        const toggleProvider = <Switch
            disabled={!configValid}
            classes={{ switchBase: classes.switchBase }}
            color="primary"
            name="enabled"
            checked={config.enabled}
            onChange={this.handleChange} />;

        const showPasswordButton = <InputAdornment position="end">
            <IconButton
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPassword}
                onMouseDown={this.handleMouseDownPassword}>
                {this.state.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
        </InputAdornment>;

        return (
            <ConfigCard>
                <SectionHeader
                    label={`Configure ${_.upperFirst(provider)} login`}
                    preview={toggleProvider}/>
                <Divider />
                <div className={classes.root}>
                    <form className={classes.form} noValidate autoComplete="off">
                        <TextField
                            className={classes.textField}
                            value={config.appId}
                            label="ID"
                            onChange={this.handleChange}
                            InputProps={{ name: 'appId' }}
                            fullWidth={isMobile}
                            helperText={`${_.upperFirst(provider)} application ID`}/>
                        <TextField
                            className={classes.textField}
                            value={config.secret}
                            label="Secret"
                            onChange={this.handleChange}
                            fullWidth={isMobile}
                            helperText={`${_.upperFirst(provider)} application secret`}
                            InputProps={{ name: 'secret', type: showPassword ? 'text' : 'password', endAdornment: showPasswordButton }}/>
                    </form>
                </div>
            </ConfigCard>
        );
    }
};

export default withWidth()(withStyles(styles)(ExternalLoginProvider));

export { externalLoginProviders };
