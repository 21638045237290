import React, { Component } from 'react';
import Vibrant from 'node-vibrant';
import filesize from 'filesize';

import { withPageBlocker } from 'AppContext';
import API from 'components/common/API';
import CssPositionSelect from 'components/common/CssPositionSelect';
import ImageUploader from 'components/common/ImageUploader';
import FileUploadErrorDialog from 'components/common/dialogs/FileUploadErrorDialog';

import { withStyles } from '@material-ui/core/styles';

import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    tools: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing.unit * 2,
    },
    previewContainer: {
        cursor: 'pointer',
        position: 'relative',
        height: theme.spacing.unit * 32,
        [theme.breakpoints.up('sm')]: {
            height: theme.spacing.unit * 24,
        },
        borderRadius: theme.spacing.unit,
        '&:hover > button': {
            opacity: 1,
        },
    },
    previewContainerEmpty: {
        backgroundColor: theme.palette.common.white,
        borderStyle: 'dotted',
        borderColor: theme.palette.grey['700'],
        borderWidth: 1,
        '& > button': {
            color: theme.palette.common.black,
            opacity: 1,
        },
    },
    uploader: {
        color: theme.palette.common.white,
        opacity: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        transition: `opacity 0.125s ${theme.transitions.easing.easeInOut}`,
    },
    mobileLabel: {
        position: 'absolute',
        right: theme.spacing.unit,
        bottom: theme.spacing.unit,
        padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit}px`,
        borderRadius: theme.spacing.unit,
        color: theme.palette.common.white,
        background: 'rgba(0, 0, 0, 0.54)',
    },
});

class BackgroundImage extends Component {

    state = {
        fileTooBig: false,
        backgroundSize: 'auto',
        backgroundPosition: 'top right',
        backgroundImage: '',
        backgroundColor: '#ffffff',
        backgroundRepeat: 'no-repeat',
    };

    handleFiles = (files) => {
        this.setState({ fileTooBig: false });
        const { blocker } = this.props;
        if (files.fileList[0].size < process.env.REACT_APP_BACKGROUND_MAX_SIZE * 1) {
            blocker.block();
            Promise.all([
                API.uploadFile(files.fileList[0]),
                Vibrant.from(files.base64).getPalette(),
            ])
            .then(([fileUrl, palette]) => {
                let backgroundColor = '#ffffff';
                try {
                    backgroundColor = palette.Vibrant.getHex();
                } catch (error) {
                    console.log(`Failed to read color dominant from image, using '#ffffff' as fallback`);
                }
                this.setState({
                    backgroundColor,
                    backgroundImage: `url(${fileUrl})`,
                }, this.updateBackground);
            })
            .finally(() => {
                blocker.unblock();
            });
        } else {
            this.setState({ fileTooBig: true });
        }
    };

    componentDidMount = () => {
        this.updateBackgroundState(this.props.background);
    }

    componentDidUpdate = (props, state) => {
        // console.log('BackgroundImage.componentDidUpdate', props, state);
        if (props.background !== state.savedBackground) {
            this.updateBackgroundState(props.background);
        }
    }

    updateBackgroundState = (background = '') => {
        const backgroundRegex = /^(#[a-fA-F0-9]+).*(?:\(['"]?)(.*?)(?:['"]?\))\s(.*)\/(auto|cover)/;
        const backgroundMatch = backgroundRegex.exec(background);
        if (backgroundMatch) {
            this.setState({
                backgroundColor: backgroundMatch[1],
                backgroundImage: `url(${backgroundMatch[2]})`,
                backgroundPosition: backgroundMatch[3],
                backgroundSize: backgroundMatch[4],
                savedBackground: background,
            });
        }
    }

    updateBackground = ({ backgroundImage, backgroundColor, backgroundPosition, backgroundSize, backgroundRepeat } = this.state) => {
        // console.log('BackgroundImage.updateBackground', `${backgroundColor} ${backgroundImage} ${backgroundPosition}/${backgroundSize} ${backgroundRepeat}`);
        this.props.onChange({ background: `${backgroundColor} ${backgroundImage} ${backgroundPosition}/${backgroundSize} ${backgroundRepeat}` });
    }

    toggleSizeMode = (event, checked) => {
        // console.log('BackgroundImage.toggleSizeMode', { backgroundSize: checked ? 'cover' : 'auto' });
        this.setState({ backgroundSize: checked ? 'cover' : 'auto' }, this.updateBackground);
    }

    handlePositionChange = ({ target: { value: backgroundPosition } }) => {
        // console.log('BackgroundImage.handlePositionChange', { backgroundPosition });
        this.setState({ backgroundPosition }, this.updateBackground);
    }

    render() {
        const { backgroundPosition, backgroundSize, backgroundImage, fileTooBig } = this.state;
        const { background, classes } = this.props;

        const uploadButton = <IconButton
            className={classes.uploader}
            color="inherit">{backgroundImage ? <PhotoCameraIcon/> : <AddAPhotoIcon/>}
        </IconButton>;

        return (
            <div className={ classes.root }>
                <div className={ classes.tools }>
                    <CssPositionSelect
                        onChange={this.handlePositionChange}
                        position={backgroundPosition} />
                    <FormControlLabel
                        label="Cover whole page"
                        control={<Switch
                            color="primary"
                            checked={backgroundSize !== 'auto'}
                            onChange={this.toggleSizeMode} />} />
                </div>
                <ImageUploader
                    handleFiles={this.handleFiles}>
                    <div
                        style={{ background }}
                        className={ `${classes.previewContainer} ${!backgroundImage ? classes.previewContainerEmpty : '' }` }>
                        <Hidden xsDown>
                            <Tooltip title="Select new image">{ uploadButton }</Tooltip>
                        </Hidden>
                        <Hidden smUp>
                            <Typography variant="caption" className={ classes.mobileLabel }>Select new image</Typography>
                        </Hidden>
                    </div>
                </ImageUploader>
                <FileUploadErrorDialog
                    open={fileTooBig}
                    title="Selected image is too big."
                    content={`Maximum allowed file size is ${filesize(process.env.REACT_APP_BACKGROUND_MAX_SIZE)}.`}
                    handleCancel={() => this.setState({ fileTooBig: false })}
                    handleClose={this.handleFiles} />
            </div>
        );
    }
}

export default withPageBlocker(withStyles(styles)(BackgroundImage));
