import React, { Component } from 'react';

import colors from 'helpers/colors';

import ColorButton from 'components/common/ColorButton';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    root: {
        // marginTop: 16,
        // marginBottom: 16,
        paddingLeft: 0,
        paddingRight: 0,
    },
});

class PalettePicker extends Component {
    handleChange = (color) => {
        this.props.onChange(color);
    }
    render() {
        const { classes, label = 'Main color', color: activeColor } = this.props;
        const currentColor = colors.toPaletteColor(activeColor);
        // console.log('PalettePicker.render', currentColor);
        return (
            <React.Fragment>
                <div
                    className={ classes.root }>
                    <Typography
                        variant="subtitle1">{ label }:</Typography>
                    <div>
                        {colors.asArray().map((color) => (<ColorButton
                            onChange={this.handleChange}
                            key={color.id}
                            selected={color.id === currentColor.id}
                            color={color.main} />))}
                    </div>
                </div>
                <div
                    className={ classes.root }>
                    <Typography
                        variant="subtitle1">{ label } variant:</Typography>
                    <div>
                        {currentColor.variants.map((colorVariant) => (<ColorButton
                            onChange={this.handleChange}
                            key={`${currentColor.main}-${colorVariant}`}
                            selected={colorVariant === activeColor}
                            color={colorVariant} />))}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(PalettePicker);
