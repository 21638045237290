import axios from 'axios';
import _ from 'lodash';

import { cas } from 'bootstrap/registerAvaCAS';

import { externalLoginProviders } from 'components/LoginPage/ExternalLoginProvider';

const apiURL = process.env.REACT_APP_CAS_URL;

class API {
    static transformInstances(instances = []) {
        if (_.isArray(instances)) {
            _.forEach(instances, API.transformInstance);
        } else {
            API.transformInstance(instances);
        }
        return instances;
    }
    static transformInstance(instance) {
        const bannerPath = 'guiSettings.theme.login.banner';
        const toNumber = (value) => value !== undefined ? value * 1 : value;
        delete instance.businessId;
        delete instance.businessName;
        _.has(instance, `${bannerPath}.fontSize`) &&  _.update(instance, `${bannerPath}.fontSize`, toNumber);
        _.has(instance, `${bannerPath}.fontWeight`) &&  _.update(instance, `${bannerPath}.fontWeight`, toNumber);
        !_.has(instance, `${bannerPath}.position`) &&  _.set(instance, `${bannerPath}.position`, 'top right');
        _.forEach(externalLoginProviders, (loginProvider) => {
            !_.has(instance, `login.${loginProvider}.appId`) &&  _.set(instance, `login.${loginProvider}.appId`, '');
            !_.has(instance, `login.${loginProvider}.secret`) &&  _.set(instance, `login.${loginProvider}.secret`, '');
        });
    };
    static toUrl(url, skipBusiness = false) {
        return `${apiURL}/${skipBusiness ? url : 'business/' + cas.activeBusiness + '/' + url}`;
    }
    static call({ url, method = 'GET', data = undefined }, skipBusiness = false) {
        return axios({
            method,
            data,
            url: API.toUrl(url, skipBusiness),
            withCredentials: true,
            headers: {
                'session-id': cas.token,
            },
        })
        .then(({ data }) => data);
    }
    getInstances() {
        return API.call({ url: 'instance' })
            .then(API.transformInstances);
    };
    getInstance(instanceId) {
        return API.call({ url: `instance/${instanceId}` })
            .then(API.transformInstances);
    };
    saveInstance(data) {
        return API.call({ url: `instance/${data.id}`, method: 'PUT', data, })
            .then(API.transformInstances);
    };
    uploadFile(file) {
        return API.call({ url: 'file/reserve', method: 'POST' }, true)
            .then(([{ id }]) => {
                const formData = new FormData();
                formData.append('file', file);
                return axios.post(API.toUrl(`file/${id}`, true), formData, {
                    withCredentials: true,
                    headers: {
                        'session-id': cas.token,
                        'content-type': 'multipart/form-data',
                    },
                }).then(({ data: { id }}) => API.toUrl(`public/file/${id}`, true));
            });
    }
}

export default new API();
