import React from 'react';
import { render } from 'react-dom';
import _ from 'lodash';
import './index.css';
import App from './App';
import bootstrap from './bootstrap';

import API from 'components/common/API';

const renderApp = (toolbarData, casData, instances) => {
    render( <App
        appName={ toolbarData.currentApplication.label }
        instances={ instances }
        toolbarData={ toolbarData } /> , document.getElementById('root'));
};

bootstrap(document)
    // .then(renderApp, renderApp)
    .then(({ cas, data }) => {
        const url = new URL(window.location.href);
        let state = cas.defaultState;
        try {
            state = Object.assign(state, JSON.parse(atob(url.searchParams.get('state'))));
            console.log(state);
        } catch (error) {
            console.log('Failed to read state, using defaults');
        }

        // the user has an active session so no need to show the login page
        // try to redirect to previous app or framework if no app found
        if (_.get(data, 'token', false)) {
            let toolbarData = {};
            cas
                // load toolbar data needed for AVA-Toolbar and info about current business
                .getToolbarData({ applicationId: process.env.REACT_APP_APPLICATION_ID })
                .then((data) => { toolbarData = data; })
                .then(() => {
                    cas.activeBusiness = _.get(toolbarData, 'currentUser.currentBusiness.id');
                    return API.getInstances();
                })
                .then((data) => cas.hasAccess(process.env.REACT_APP_APPLICATION_ID, data))
                .then((instances = []) => {
                    cas.hideLoader();
                    renderApp(toolbarData, cas.data, instances);
                }, (error) => {
                    console.log('ups', error);
                    cas.goToPortal();
                });
        } else {
            cas
                .goToLogin({ continue: window.location.href });
        }
    }, (error) => {
        // the iframe doesn't work - something's wrong :(
        console.log(error);
        // renderApp();
    });
