import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing.unit * 2,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 3,
            paddingRight: theme.spacing.unit * 3,
        },
    },
    flex: {
        flex: 1,
    },
    secondary: {
        marginTop: theme.spacing.unit,
    }
});

class SectionHeader extends Component {
    render() {
        const { className = '', classes, label, secondaryContent, preview } = this.props;
        return (
            <div
                className={`${classes.root} ${className}`}>
                <FormControl
                    className={classes.flex}>
                    <FormLabel><Typography variant="subtitle1">{ label }</Typography></FormLabel>
                    {secondaryContent && (<div className={classes.secondary}>{secondaryContent}</div>) }
                </FormControl>
                { preview }
            </div>
        );
    }
}

export default withStyles(styles)(SectionHeader);
