import React, { Component } from 'react';

import BackgroundSelector from './BackgroundSelector';
import ImageSelector from './ImageSelector';
import TextSelector from './TextSelector';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    card: {
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 16,
    },
});

class WelcomePage extends Component {
    handleImgChange = ({ imgSrc: backgroundPath}) => {
        this.props.onChange({ backgroundPath });
    };
    handleBackgroundChange = ({ background }) => {
        this.props.onChange({ background });
    };
    handleBannerChange = (banner) => {
        this.props.onChange({ banner });
    };
    render() {
        const { classes, themeData: { banner, background, backgroundPath } } = this.props;

        // console.log('WelcomePage', { banner, background, backgroundColor, backgroundImage });

        return (
            <React.Fragment>
                <BackgroundSelector
                    label="Background"
                    background={background}
                    onChange={this.handleBackgroundChange}
                    elevation={2}
                    className={ classes.card } />
                <ImageSelector
                    label="Center image"
                    imgSrc={backgroundPath}
                    onChange={this.handleImgChange}
                    onBlock={this.props.onBlock}
                    elevation={2}
                    className={ classes.card }/>
                <TextSelector
                    label="Banner:"
                    line1={banner.line1}
                    line2={banner.line2}
                    color={banner.color}
                    position={banner.position}
                    fontSize={banner.fontSize}
                    fontWeight={banner.fontWeight}
                    onChange={this.handleBannerChange}
                    elevation={2}
                    className={ classes.card }/>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(WelcomePage);
