import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import _ from 'lodash';
import deepAssign from 'deep-assign';
import merge from 'deepmerge';

import './App.css';

import AppContext from 'AppContext';
import colors from 'helpers/colors';
import API from 'components/common/API';

import { MainBar } from '@avazanga/components-react';

import { cas } from 'bootstrap/registerAvaCAS';

import SideDrawer from 'components/SideDrawer';
import LoginPage from 'components/LoginPage';

import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';

import {
    AppBar,
    CircularProgress,
    CssBaseline,
    Grid,
    Hidden,
    IconButton,
    Toolbar,
    Typography,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

const mainTheme = createMuiTheme({
    palette: {
        primary: { main: '#37495A' },
        secondary: { main: colors.muiColors.lightBlue['800'] },
    },
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiDialog: {
            paperWidthSm: {
                minWidth: 480,
            },
            paperFullScreen: {
                minWidth: 'auto',
            },
        },
    },
});

const styles = (theme) => ({
    content: {
        overflow: 'auto',
    },
});

const progressSize = 80;

class App extends Component {
    state = {
        activeInstance: null,
        mobileOpen: false,
        inProgress: false,
    };

    componentDidMount = () => {
        this.handleInstanceChange(_.get(this.state, 'activeInstance.id', this.props.instances[0].id));
        this.setState({ context: {
            block: this.blockPage,
            unblock: this.unblockPage,
        }});
    };

    handleInstanceChange = (id) => {
        if (id !== _.get(this.state, 'activeInstance.id')) {
            colors.cleanup();
            this.setState({ activeInstance: _.find(this.props.instances, { id }) });
        }
    };

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };

    blockPage = () => {
        this.setState({ inProgress: true });
    };

    unblockPage = () => {
        this.setState({ inProgress: false });
    };

    handleSignOut = () => {
        cas.logout()
            .then(() => cas.goToLogin({ continue: window.location.href }));
    };

    handleLoginChange = (instanceData) => {
        console.log('App.handleLoginChange', instanceData);
        this.blockPage();
        API
            .saveInstance(merge(this.state.activeInstance, instanceData))
            .then((instance) => {
                this.setState({ activeInstance: deepAssign(this.state.activeInstance, instance), }, this.unblockPage);
            });
    };

    render() {
        const { toolbarData, appName, classes, instances } = this.props;
        const { mobileOpen, activeInstance, inProgress } = this.state;

        if (!activeInstance) {
            return (<div>ERROR</div>);
        }

        const themeData = _.get(activeInstance, 'guiSettings.theme');
        const loginData = _.get(activeInstance, 'login');

        return (
            <React.Fragment>
                <Helmet>
                    <title>{ appName }</title>
                </Helmet>
                <CssBaseline>
                    <div
                        id="instance-manager">
                        <MainBar
                            appName={ appName }
                            handleSignOut={ this.handleSignOut }
                            { ...toolbarData } />
                        <MuiThemeProvider
                            theme={ mainTheme }>
                            <Grid
                                item
                                xs
                                container>
                                <SideDrawer
                                    appName={ appName }
                                    activeInstance={activeInstance.id}
                                    instances={instances}
                                    onInstanceChange={this.handleInstanceChange}
                                    isOpen={mobileOpen}
                                    handleDrawerToggle={this.handleDrawerToggle}/>
                                <Grid
                                    style={{ position: 'relative' }}
                                    xs
                                    direction="column"
                                    container
                                    item>
                                    <AppBar
                                        position="static">
                                        <Toolbar>
                                            <Hidden mdUp>
                                                <IconButton
                                                    color="inherit"
                                                    className="toolbar-icon"
                                                    onClick={this.handleDrawerToggle}>
                                                    <MenuIcon />
                                                </IconButton>
                                            </Hidden>
                                            <Typography
                                                className={ classes.flex }
                                                color="inherit"
                                                variant="h6">Login Page</Typography>
                                        </Toolbar>
                                    </AppBar>
                                    <Grid
                                        className={ classes.content }
                                        xs
                                        item>
                                        <AppContext.Provider
                                            value={this.state.context}>
                                            <LoginPage
                                                key={activeInstance.id}
                                                onBlock={this.handleOnBlock}
                                                onChange={this.handleLoginChange}
                                                login={loginData}
                                                data={themeData}/>
                                        </AppContext.Provider>
                                    </Grid>
                                    {inProgress && (
                                    <div id="Content-Blocker">
                                        <CircularProgress
                                            size={ progressSize }
                                            style={{ color: 'rgba(255, 255, 255, 0.87)', marginLeft: -progressSize / 2, marginTop: -progressSize / 2, }}
                                            className="spinner"
                                            variant="indeterminate"/>
                                    </div>)}
                                </Grid>
                            </Grid>
                        </MuiThemeProvider>
                    </div>
                </CssBaseline>
            </React.Fragment>
        );
    }
}

export default withStyles(styles, { withTheme: true })(App);
